<template>
  <li>
    <item-card>
      <template v-slot:content>
        <div class="flex flex-wrap lg:flex-nowrap w-full">
          <template v-if="editableItem.id !== item.id">
            <div class="grid grid-cols-12">
              <div class="col-span-12 sm:col-span-6 lg:col-span-5 xl:col-span-4">
                <item-text title="Nosaukums" :text="item.name" />
              </div>
              <div class="col-span-12 sm:col-span-6">
                <item-text title="SWIFT" :text="item.swift" />
              </div>
            </div>
          </template>

          <div class="ml-auto flex items-center">
            <template v-if="editableItem.id !== item.id">

              <Button icon="pencil" @click="editItem(item)" />

              <Button icon="delete" @click="deleteBank(item.id)" />

            </template>
          </div>

        </div>
      </template>

      <template v-slot:additional>
        <template v-if="editableItem.id === item.id">
          <EditBank :item="item"/>
        </template>
      </template>

    </item-card>
  </li>
</template>

<script>
import {mapGetters} from "vuex"
import ItemCard from "@/components/Components/ItemCard"
import ItemText from "@/components/Components/ItemText"
import EditBank from "@/components/Settings/CompanySettings/Banks/EditBank"

export default {
  name: "SingleBank",
  components: {
    ItemCard,
    ItemText,
    EditBank,
  },
  props: ['item'],
  computed: {
    ...mapGetters({
      editableItem: 'editableItem'
    })
  },
  methods: {
    editItem(item){
      this.$store.dispatch('setEditableItem', item)
    },
    deleteBank(itemId){
      if(confirm('Vai esi drošs?')){
        this.$Progress.start()
        this.$store.dispatch('deleteBank', itemId)
      }
    }
  }
}
</script>
